import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6099f074"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list" }
const _hoisted_2 = { class: "grid grid-cols-1 gap-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_Filter = _resolveComponent("Filter")!
  const _component_ListItem = _resolveComponent("ListItem")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.componentData.title)
      ? (_openBlock(), _createBlock(_component_Title, {
          key: 0,
          title: _ctx.componentData.title
        }, null, 8, ["title"]))
      : _createCommentVNode("", true),
    (_ctx.filterOptions)
      ? (_openBlock(), _createBlock(_component_Filter, {
          key: 1,
          label: "name",
          options: _ctx.filterOptions,
          onFilterSelect: _ctx.filterSelect
        }, null, 8, ["options", "onFilterSelect"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, key) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "item",
          key: key,
          ref_for: true,
          ref: _ctx.setItemRef
        }, [
          _createVNode(_component_router_link, {
            to: '/game/' + item.slug
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ListItem, {
                data: item,
                observer: _ctx.observer
              }, null, 8, ["data", "observer"])
            ]),
            _: 2
          }, 1032, ["to"])
        ]))
      }), 128))
    ])
  ]))
}