import Component from "@/api/models/component";
import ApiComponent from "@/api/models/apicomponent";

export default class Page {
    componentRefs: []
    apiRef: any
    components: Component[]

    constructor (props: any) {
        this.apiRef = props.apiRef;
        this.componentRefs = props.componentRefs;
        this.components = [new Component({})];
    }

    retrieveComponents (start?: number, end?: number) {
        const resolvedComponents: any = [];
        const componentRefs: any = [];
        const promises: any[] = [];

        this.componentRefs.forEach(c => {

            switch(typeof c) {
                case "object":
                    resolvedComponents.push(new ApiComponent(c, this.apiRef));
                    promises.push(resolvedComponents[resolvedComponents.length - 1].retrieveData());
                    break;
                case "string":
                    componentRefs.push(c);
                    resolvedComponents.push(c);
                    break;
            }
        });

        if (componentRefs.length) {
            promises.push(this.retrieveComponentRefs(componentRefs));
        }

        return Promise.all(promises)
            .then(r => {
                const components = r[0];
                const promise: any[] = [];

                if (components) {
                    components.map((c:any) => {

                        if (c.content?.component === 'APIContent') {
                            const apiComponent = new ApiComponent(c, this.apiRef);
                            resolvedComponents[resolvedComponents.indexOf(c.uuid)] = apiComponent;

                            promise.push(apiComponent.retrieveData());
                        } else {
                            resolvedComponents[resolvedComponents.indexOf(c.uuid)] = new Component(c, this.apiRef);
                        }
                    });
                }

                this.components = resolvedComponents;

                return Promise.all(promise)
                    .then(() => {
                        return this;
                    });
            });
    }

    retrieveComponentRefs (componentRefs: string[]) {
        return this.apiRef.retrieveComponents({
            componentRefs: componentRefs
        }).then((d: any) => {
            return d?.data?.stories;
        });
    }
}