
import {defineComponent} from "vue";

import SwiperCore, { Navigation, Autoplay } from 'swiper';
// @ts-ignore
import {Swiper, SwiperSlide} from "swiper/vue/swiper-vue";

import Component from "@/api/models/component";
import HeaderItem from "@/components/browse/HeaderItem.vue";
import ContentItem from "@/api/models/contentitem";

SwiperCore.use([ Navigation, Autoplay ]);

export default defineComponent({
  name: 'NewHeader',
  components: {
    HeaderItem,
    Swiper,
    SwiperSlide
  },
  props: {
    componentData: {
      type: Component,
      default: {}
    },
    itemCount: {
      default: 5
    },
    wrap: {
      default: true
    }
  },
  data: function () {
    return {
      slidesPerView: 1
    }
  },
  methods: {
    navigate: function (item: ContentItem) {
      this.$router.push(`/game/${item.slug}`);
    }
  }
})
