
import {defineComponent} from "vue";

export default defineComponent({
  name: 'Message',
  props: {
    message: {
      default: () => {
        return 'Message'
      }
    }
  }
});
