
import Title from "@/components/browse/Title.vue";
import {defineComponent} from "vue";
import ListItem from "@/components/browse/tiles/ListItem.vue";
import ContentItem from "@/api/models/contentitem";
import Filter from "@/components/browse/Filter.vue";

export default defineComponent({
  name: 'List',
  components: {
    ListItem,
    Title,
    Filter
  },
  props: {
    componentData: {
      type: Object as any
    },
    filterOptions: {
      type: Array
    }
  },
  beforeMount() {
      this.items = this.componentData?.items || [] as ContentItem[];
  },
  mounted: function () {
      this.observer = new IntersectionObserver(this.onObserve);
      this.itemRefs.map(e => this.observer.observe(e));
  },
  data: function () {
    return {
      canLoadMore: true,
      observer: {} as IntersectionObserver,
      itemRefs: [] as HTMLElement[],
      loading: false,
      items: [] as ContentItem[],
      activeFilter: null as any
    }
  },
  methods: {
    filterSelect: function (e: any) {
      this.activeFilter = e;
      this.itemRefs = [] as HTMLElement[];

      if (e.reset) {
        this.canLoadMore = true;
        this.activeFilter = null;
        this.componentData.retrieveData()
        .then(() => {
          this.items = this.componentData.items;
        });
      } else {
        this.componentData.retrieveData({
          'with_tag': this.activeFilter.name // So much for not being dependant on Storyblok...
        })
        .then(() => {
          this.items = this.componentData.items;
        });
      }
    },
    setItemRef: function (e: any) {
      if (!e) {
        return;
      }

      if (!this.itemRefs.includes(e)) {
        this.itemRefs.push(e);

        if (this.observer && this.observer.observe) {
          this.observer.observe(e);
        }
      }
    },
    loadMore: function () {
      let filter = {};

      if (this.activeFilter) {
        filter = {
          'with_tag': this.activeFilter.name
        }
      }

      if (!this.canLoadMore) {
        return;
      }

      this.componentData?.getNextPage(filter)
      .then((items: ContentItem[]) => {
        if (items.length === 0) {
          this.canLoadMore = false;
        }

        this.items = this.items.concat(items);
        this.loading = false;
      });
    },
    onObserve: function (e: any, observer: IntersectionObserver) {

      e.forEach((item: any) => {
        if (item.isIntersecting) {
          observer.unobserve(item.target);

          if (this.itemRefs.indexOf(item.target) >= this.itemRefs.length - 5 && !this.loading) {
            this.loading = true;
            this.loadMore();
          }
        }
      })
    }
  }
});
