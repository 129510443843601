import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Grid = _resolveComponent("Grid")!
  const _component_Header = _resolveComponent("Header")!
  const _component_List = _resolveComponent("List")!
  const _component_Carousel = _resolveComponent("Carousel")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["browse", { 'top-margin': _ctx.topMargin} ])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.components, (component, key) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "component",
        key: key
      }, [
        (_ctx.isGrid(component) && component.items?.length)
          ? (_openBlock(), _createBlock(_component_Grid, {
              key: 0,
              "component-data": component
            }, null, 8, ["component-data"]))
          : (_ctx.isHeader(component) && component.items?.length)
            ? (_openBlock(), _createBlock(_component_Header, {
                key: 1,
                "component-data": component
              }, null, 8, ["component-data"]))
            : (_ctx.isList(component) && component.items?.length)
              ? (_openBlock(), _createBlock(_component_List, {
                  key: 2,
                  "component-data": component
                }, null, 8, ["component-data"]))
              : (_ctx.isCarousel(component) && component.items?.length)
                ? (_openBlock(), _createBlock(_component_Carousel, {
                    key: 3,
                    "component-data": component
                  }, null, 8, ["component-data"]))
                : _createCommentVNode("", true)
      ]))
    }), 128))
  ], 2))
}