import StoryblokInterface from "@/api/storyblok/interface";
import ContentItem from "@/api/models/contentitem";

export default class ApiComponent {
    items?: Array<any>
    params?: any
    api?: string
    type?: string
    tileType?: string
    apiRef: StoryblokInterface
    title: string
    canLoadMore: boolean
    page: number

    constructor(props: any, apiRef: StoryblokInterface) {
        this.apiRef = apiRef;
        this.api = props.content?.api;
        this.type = props.content?.type;
        this.tileType = props.content?.tile;
        this.title = props.content?.title;
        this.canLoadMore = true;
        this.page = props.content?.page || 1;

        if (props.content?.params) {
            this.params = JSON.parse(props.content.params); // Storyblok...
        }
    }

    retrieveData (additionalParams?: Object) {
        const {
            api,
            params
        } = this;

        this.page = 1;
        this.params.page = 1;

        return this.apiRef.getApiComponent({
            slug: api,
            params: Object.assign({}, params, additionalParams)
        }).then((componentData: any) => {
            this.items = componentData.items;
        });
    }

    getNextPage (additionalParams?: Object) {
        const {
            api,
            params
        } = this;

        params.page = ++this.page;

        if (this.canLoadMore) {

            return this.apiRef.getApiComponent({
                slug: api,
                params: Object.assign({}, params, additionalParams)
            }).then((componentData:any) => {
                return componentData.items;
            });
        }
    }
}