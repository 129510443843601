<template>
	<div class="arrow">
		<div :class="`gg-chevron-${direction}`" class="icon" @mouseenter="mouseEnter"></div>
	</div>
</template>

<script>
export default {
	name: 'Arrow',
	props: {
		direction: {
			type: String,
			default: 'left'
		}
	},
	methods: {
		mouseEnter: function () {
			this.$global.video.hide();
		}
	}
}
</script>

<style scoped lang="scss">
.arrow {
	color: $fontColor;
	margin: 0 5px;
	cursor: pointer;
	z-index: 5;
	height: 100%;
	top: 0;
	width: 2.5em;

	.icon {
		margin: auto;
		--ggs: 2;
		background-color: black;
		top: 50%;
		transform:translate(-50%,-50%) scale(var(--ggs, 1));
	}
}
</style>