import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, KeepAlive as _KeepAlive, Transition as _Transition, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a1ed07c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["content"]
const _hoisted_2 = ["content"]
const _hoisted_3 = ["content"]
const _hoisted_4 = ["content"]
const _hoisted_5 = ["content"]
const _hoisted_6 = ["content"]
const _hoisted_7 = ["content"]
const _hoisted_8 = ["content"]
const _hoisted_9 = { class: "content mx-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")!
  const _component_Head = _resolveComponent("Head")!
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Video = _resolveComponent("Video")!
  const _component_CookieConsent = _resolveComponent("CookieConsent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Loader, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _createVNode(_component_Head, null, {
        default: _withCtx(() => [
          _createElementVNode("title", null, _toDisplayString(_ctx.meta.title), 1),
          _createElementVNode("meta", {
            name: "description",
            content: _ctx.meta.description
          }, null, 8, _hoisted_1),
          _createElementVNode("meta", {
            property: "og:title",
            content: _ctx.meta.title
          }, null, 8, _hoisted_2),
          _createElementVNode("meta", {
            property: "og:description",
            content: _ctx.meta.description
          }, null, 8, _hoisted_3),
          _createElementVNode("meta", {
            property: "og:image",
            content: _ctx.meta.image || ''
          }, null, 8, _hoisted_4),
          _createElementVNode("meta", {
            property: "og.video",
            content: _ctx.meta.video || ''
          }, null, 8, _hoisted_5),
          _createElementVNode("meta", {
            itemprop: "description",
            content: _ctx.meta.description
          }, null, 8, _hoisted_6),
          _createElementVNode("meta", {
            itemprop: "name",
            content: _ctx.meta.title
          }, null, 8, _hoisted_7),
          _createElementVNode("meta", {
            itemprop: "image",
            content: _ctx.meta.image || ''
          }, null, 8, _hoisted_8)
        ]),
        _: 1
      }),
      _createVNode(_component_Navigation, {
        items: _ctx.menuItems,
        onNavigate: _ctx.navigate
      }, null, 8, ["items", "onNavigate"]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_router_view, null, {
          default: _withCtx(({ Component }) => [
            _createVNode(_Transition, { name: "fade" }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_KeepAlive, null, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                    key: _ctx.$route.fullPath
                  }))
                ], 1024))
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_Video, { ref: "video" }, null, 512),
      (_ctx.cookieConsent)
        ? (_openBlock(), _createBlock(_component_CookieConsent, {
            key: 0,
            onConsentConfirmed: _ctx.onConsentConfirmed
          }, null, 8, ["onConsentConfirmed"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}