

import {defineComponent} from "vue";

export default defineComponent({
  name: 'Navigation',
  data: () => {
    return {
      menuOpen: false
    }
  },
  props: {
    title: {
      type: String,
      default: () => 'EscapeGaming.io'
    },
    items: {
      type: Array,
      default: () => {
        return [
          {
            label: 'Home',
            path: '/'
          },
          {
            label: 'Games',
            path: 'games'
          },
          {
            label: 'Search',
            path: 'search'
          },
          {
            label: 'Can\'t decide?',
            path: 'cant-decide'
          },
          {
            label: 'Contact',
            path: 'contact'
          }
        ]
      }
    }
  },
  methods: {
    navigate: function () {
      //this.$emit('navigate', item);
      this.toggleMenu();
    },
    toggleMenu: function () {
      this.menuOpen = !this.menuOpen;
    }
  }
});
