
import {defineComponent} from "vue";
import ContentItem from "../../api/models/contentitem";

export default defineComponent({
  props: {
    item: {
      type: ContentItem
    },
    showTitle: {
      type: Boolean
    }
  },
  data: function () {
    return {
      playNow: this.$lang.get('play_now')
    }
  }
})
