
import {defineComponent} from "vue";
import {Asset, Ankeiler} from "@/components/browse/tiles";
import Component from "@/api/models/component";
import Title from "@/components/browse/Title.vue";

export default defineComponent({
  name: 'Grid',
  components: {
    Title,
    Asset,
    Ankeiler
  },
  props: {
    componentData: {
      type: Component,
      default: {}
    }
  },
  data: function () {
    return {
      isAssetType: this.componentData.tileType === 'asset',
      isAnkeilerType: this.componentData.tileType === 'ankeiler'
    }
  }
});
