import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-69d3f6e1"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("input", {
    type: "text",
    onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.callback && _ctx.callback(...args))),
    placeholder: _ctx.$lang.get('search'),
    class: "input rounded-lg font-montserrat-bold"
  }, null, 40, _hoisted_1))
}