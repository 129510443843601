import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7dd9f065"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "asset" }
const _hoisted_2 = { class: "description font-lato-regular" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Ankeiler = _resolveComponent("Ankeiler")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Ankeiler, { data: _ctx.data }, null, 8, ["data"]),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.data.description), 1)
  ]))
}