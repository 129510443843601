
import {defineComponent} from "vue";

export default defineComponent({
  data: function () {
    return {
      style: {} as any,
      source: '',
      muted: true,
      videoTimeout: null as any,
      visible: false,
      scope: null as any
    }
  },
  methods: {
    setPosition: function (opts: any) {
      const menuAdjustment = window.innerWidth >= 1024 ? 70 : 50; // Magic numbers

      const {
        source,
          left,
          top,
          width,
          height,
          scope
      } = opts;

      const bodyStyle = document.body.getBoundingClientRect();
      const bodyTop = bodyStyle.top;
      const bodyLeft = bodyStyle.left;

      this.style = {
        top: top - bodyTop + menuAdjustment + 'px',
        left: left - bodyLeft + 'px',
        width: width + 'px',
        height: height + 'px',
      }

      this.scope = scope;

      this.source = source;
    },

    hide: function () {
      clearTimeout(this.videoTimeout);
      this.visible = false;
      this.scope = null;

      const videoElement = this.$refs.videoElement as HTMLVideoElement;
      this.style.opacity = '0';

      videoElement.pause();
      this.source = '';
    },

    show: function () {
      const videoElement = this.$refs.videoElement as HTMLVideoElement;
      this.visible = true;

      this.videoTimeout = setTimeout(() => {
        this.style.opacity = '1';
        videoElement.play();
      }, 1000);
    },

    unmute () {
      this.muted = false;
      document.removeEventListener('click', this.unmute);
    },

    click () {
      this.scope && this.scope.$refs.elem.click();
      this.hide();
    }
  },
  beforeMount () {
    document.addEventListener('click', this.unmute);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.unmute);
  }
})
