
import ContentItem from "@/api/models/contentitem";
import {defineComponent} from "vue";

export default defineComponent({
  name: 'Ankeiler',
  data: function () {
    return {
      style: {
        backgroundImage: `url(${this.data.images?.tile})`
      }
    }
  },
  props: {
    data: {
      type: ContentItem,
      default: {
        title: 'Title',
        tags: ['multiplayer', 'co-op']
      }
    }
  },
  methods: {

    mouseEnter: function (e: any) {
      const style = e.target.getBoundingClientRect();

      if (this.data.videos?.trailer) {
        
        // @ts-ignore
        this.$global.video.setPosition({
          source: this.data.videos?.trailer,
          top: style.top,
          left: style.left,
          width: style.width,
          height: style.height,
          scope: this
        });

        // @ts-ignore
        this.$global.video.show();
      }
    }
  }
});
