import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b1566ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cookieconsent" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "text" }
const _hoisted_4 = { class: "options" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$lang.get('cookieTitle')), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: "option",
          id: "accept-consent",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.consent && _ctx.consent(...args)))
        }, _toDisplayString(_ctx.$lang.get('cookieAccept')), 1),
        _createElementVNode("div", {
          class: "option",
          id: "deny-consent",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.consent && _ctx.consent(...args)))
        }, _toDisplayString(_ctx.$lang.get('cookieDeny')), 1)
      ])
    ])
  ]))
}