import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './style/index.scss';
import StoryblokInterface from "@/api/storyblok/interface";
import Language from "@/utils/lang";
import Globals from "@/utils/globals";
import { createHead } from '@vueuse/head';

import VueGtag from "vue-gtag-next";
import { VueCookieNext } from 'vue-cookie-next';

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $api: StoryblokInterface,
        // @ts-ignore
        $lang: Language,

        $global: Globals
    }
}

const head = createHead();
const app = createApp(App);

app.use(head);
app.config.globalProperties.$api = new StoryblokInterface(
    'https://api.escapegaming.io' // TODO add to config.
);
app.config.globalProperties.$lang = new Language();
app.config.globalProperties.$global = new Globals();

app.use(VueGtag, {
    property: {
        id: 'G-HGZTJM85HQ',
        params: {
            send_page_view: true
        }
    },
    isEnabled: true
});
app.use(VueCookieNext);

app.use(router).mount('#app')
