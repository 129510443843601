import { createRouter, createWebHistory } from 'vue-router'
import Config from '../config';

const routes = [
  {
    path: '/construction',
    name: 'Construction',
    component: () => import('../views/Construction.vue')
  },
  {
    path: '/game/:slug',
    name: 'Details',
    component: () => import ('../views/Details.vue')
  }
];

const router = createRouter({
  linkActiveClass: 'active',
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, position) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (!position) {
          position = { left: 0, top: 0 };
        }
        resolve(position);
      }, 400)
    })
  }
});

router.beforeEach((to, from, next) => {

  if (Config.UNDER_CONSTRUCTION && to.name !== 'Construction') {
    next('/construction');
  }
  next();
});

export default router
