
import MenuItem from "@/api/models/menuitem";
import Menu from "@/api/models/menu";
import { Browse, Search, Overview } from "@/views";
import Navigation from "@/components/Navigation.vue";
import {defineComponent} from "vue";
import Video from "@/components/browse/Video.vue";
import {trackRouter} from "vue-gtag-next";
import CookieConsent from "@/components/CookieConsent.vue";
import Loader from "@/components/Loader.vue";
import {Head} from "@vueuse/head";

export default defineComponent({
  components: {
    Video,
    Navigation,
    CookieConsent,
    Loader,
    Head
  },
  methods: {
    setMeta: function (meta: any) {
        this.meta = meta;
    },
    navigate: function (item: MenuItem) {
      this.$router.push(item.path);
    },
    setMenu: function (menu: Menu) {
      this.menuItems = menu.items;

      menu.items.forEach(i => this.setupRoute(i));
      trackRouter(this.$router);

      setTimeout(() => {
        this.loading = false;
      }, 2000); // Do I want to hardcode this or wait for API to finish and then go?

      // Reload route because we can execute it now
      this.$router.replace(location.pathname);
    },
    setupRoute: function (item: MenuItem) {
      const route: any = {
        path: item.path,
        component: this.getRouteComponent(item.type),
        name: item.slug,
        meta: {
          slug: item.slug,
          params: item.params,
          label: item.label
        }
      }
      this.$router.addRoute(route);
    },
    getRouteComponent: function (type: string) {
      switch (type) {
        case 'browse':
          return Browse;
        case 'search':
          return Search;
        case 'overview':
          return Overview;
      }
    },
    onConsentConfirmed () {
      this.cookieConsent = false;
    }
  },
  data: () => {
    return {
      loading: true,
      menuItems: {},
      cookieConsent: false,
      meta: {
        title: '',
        description: '',
        image: '',
        video: ''
      }
    }
  },
  beforeMount: function () {
    this.$api.getMenu()
    .then(this.setMenu.bind(this));
  },
  mounted: function () {
    this.$global.setGlobal('video', this.$refs.video);
    this.$global.listenMetaChanges = this.setMeta;
  }
});
