import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0827bc6d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title font-montserrat-bold lg:text-3xl text-2xl" }
const _hoisted_2 = { class: "big" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("h2", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.title.split(' '), (first, key) => {
      return (_openBlock(), _createElementBlock("span", { key: key }, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(first.substring(0, 1)), 1),
        _createTextVNode(_toDisplayString(first.substring(1)) + " ", 1)
      ]))
    }), 128))
  ]))
}