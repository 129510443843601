import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b3d06a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "muted"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["video", _ctx.visible ? 'visible' : 'hidden']),
    style: _normalizeStyle(_ctx.style)
  }, [
    _createElementVNode("video", {
      src: _ctx.source,
      onMouseleave: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.hide && _ctx.hide(...args))),
      ref: "videoElement",
      preload: "metadata",
      muted: _ctx.muted,
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.click && _ctx.click(...args)))
    }, null, 40, _hoisted_1)
  ], 6))
}