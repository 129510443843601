

import {defineComponent} from "vue";
import Component from "@/api/models/component";
import List from "@/components/browse/List.vue";
import Page from "@/api/models/page";

export default defineComponent({
  name: 'Overview',
  components: {
    List,
  },
  activated () {
    const meta = {
      title: `${this.$route.meta.label} | EscapeGaming.io - Your home for escape room & puzzle games`,
      description: 'Find your favourite escape the room and puzzle games, or discover new games to learn and love!'
    }

    this.$global.updateMeta(meta);
  },
  created () {
    const slug = this.$route.meta.slug as string;

    Promise.all([this.getPage(slug), this.getTags()])
    .then((r) => {
      const page = r[0];
      const tags = r[1];

      tags.splice(0, 0, {
        name: 'Filter on tag',
        reset: true
      });

      this.pageData = page;
      this.components = page.components;
      this.topMargin = !!(this.components[0] && this.components[0].title);

      this.tags = tags;
    });
  },
  data: () => {
    return {
      components: {} as Array<Component>,
      pageData: {} as Page,
      topMargin: false,
      tags: [] as any
    }
  },
  methods: {
    getPage (slug: string) {
      return this.$api.getPage({
        slug
      });
    },
    getTags () {
      return this.$api.getTags();
    },
    isGrid: (component: Component) => {
      return component.type === 'grid';
    },
    isHeader: (component: Component) => {
      return component.type === 'header';
    },
    isList: (component: Component) => {
      return component.type === 'list';
    },
    isCarousel: (component: Component) => {
      return component.type === 'carousel';
    }
  }
});
