
import {defineComponent} from "vue";
import {setCookieConsent} from "@/utils/consent";

export default defineComponent({
  name: 'CookieConsent',
  methods: {
    consent (e: any) {
      const id = e.target.id;

      setCookieConsent(id === 'accept-consent');
      location.reload();

      this.$emit('consent-confirmed');
    }
  }
})
