
import {defineComponent} from "vue";

export default defineComponent({
  name: 'Title',
  props: {
    title: {
      type: String,
      default: () => {
        return 'Title'
      }
    }
  }
});
