import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-311cde94"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search" }
const _hoisted_2 = { class: "search-input" }
const _hoisted_3 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_Message = _resolveComponent("Message")!
  const _component_Grid = _resolveComponent("Grid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SearchInput, { callback: _ctx.searchCallback }, null, 8, ["callback"])
    ]),
    (_ctx.showHint)
      ? (_openBlock(), _createBlock(_component_Message, {
          key: 0,
          message: _ctx.$lang.get('searchHint'),
          class: "message"
        }, null, 8, ["message"]))
      : _createCommentVNode("", true),
    (_ctx.showNoResults)
      ? (_openBlock(), _createBlock(_component_Message, {
          key: 1,
          message: _ctx.$lang.get('searchNoResults'),
          class: "message"
        }, null, 8, ["message"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.component.items?.length)
        ? (_openBlock(), _createBlock(_component_Grid, {
            key: 0,
            "component-data": _ctx.component
          }, null, 8, ["component-data"]))
        : _createCommentVNode("", true)
    ])
  ]))
}