export default class Globals {

    setGlobal (name: string, global: any) {
        this[name as keyof Globals] = global;
    }

    listenMetaChanges (meta: any) {

        // Use it.
    }

    updateMeta (meta: any) {
        this.listenMetaChanges(meta);
    }

}