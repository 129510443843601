import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-73a03a13"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "carousel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderItem = _resolveComponent("HeaderItem")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_SwiperSlide = _resolveComponent("SwiperSlide")!
  const _component_Swiper = _resolveComponent("Swiper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Swiper, {
      "slides-per-view": _ctx.slidesPerView,
      "space-between": 20,
      loop: true,
      autoplay: { delay: 4000 },
      navigation: ""
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.componentData.items, (item, key) => {
          return (_openBlock(), _createBlock(_component_SwiperSlide, { key: key }, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, {
                to: '/game/' + item.slug
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_HeaderItem, {
                    item: item,
                    "show-title": true
                  }, null, 8, ["item"])
                ]),
                _: 2
              }, 1032, ["to"])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["slides-per-view"])
  ]))
}