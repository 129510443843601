import ContentItem from "@/api/models/contentitem";
import Interface from "@/api/interface";

export default class Component {
    items: ContentItem[]
    title: String
    type: String
    tileType: String
    page?: Number
    canLoadMore: Boolean
    apiRef?: Interface

    constructor(props: any, apiRef?: Interface) {
        this.items = (props.content?.content || []).map((c:any) => new ContentItem(c));
        this.title = props.content?.title;
        this.type = props.content?.type;
        this.tileType = props.content?.tile;
        this.page = props.content?.page || 1; // default page to 1.
        this.canLoadMore = true;
        this.apiRef = apiRef;
    }

    getNextPage () {
        return Promise.resolve([] as ContentItem[]);
    }
}