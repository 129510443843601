import { useCookie } from "vue-cookie-next";
import { useState } from "vue-gtag-next";

const cookieName = 'walnuts';
let cookieModule: any;

export function enableGA () {
    const cookie = getCookieModule();

    if (cookie.getCookie(cookieName) === 'enabled') {
        const { isEnabled } = useState();

        if (isEnabled) {
            isEnabled.value = true;
        }
    }
}

export function shouldShowCookieConsent () {
    const cookie = getCookieModule();

    return !cookie.isCookieAvailable(cookieName);
}

export function setCookieConsent (enabled: boolean) {
    const cookie = getCookieModule();

    if (enabled) {
        cookie.setCookie(cookieName, 'enabled', {
            expire: '6m'
        });
        enableGA();
    } else {
        cookie.setCookie(cookieName, 'disabled', {
            expire: '1m' // Am I an asshole for doing this?
        });
    }
}

function getCookieModule () {

    if (!cookieModule) {
        cookieModule = useCookie();
    }

    return cookieModule;
}