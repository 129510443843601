import Interface from "../interface";
import axios, { AxiosInstance } from "axios";
import Menu from "@/api/models/menu";
import Page from "@/api/models/page";
import ContentItem from "@/api/models/contentitem";
import Component from "@/api/models/component";
import Localise from "@/components/Localise";

export default class StoryblokInterface extends Interface {
    baseURL: string
    instance: AxiosInstance

    constructor (baseURL: string) {
        super();

        this.baseURL = baseURL;

        this.instance = axios.create({
            baseURL
        });
    }

    call (props: any) {
        const {
            method,
            path,
            body,
            contentType,
            headers,
            withCredentials,
        } = props;

        let params = props.params;

        if (!params) {
            params = {};
        }

        const date = new Date();

        params.version = Math.floor(date.getTime() / 1000);

        return this.instance.request({
            method,
            url: path,
            headers,
            data: body,
            withCredentials,
            responseType: contentType,
            params
        });
    }

    getMenu () {
        return this.call({
            method: 'get',
            path: '/stories/menu',
            contentType: 'json'
        }).then((d) => {
            return new Menu(d.data.story.content.items);
        });
    }

    getPage (props: any) {
        const {
            slug,
            params
        } = props;

        return this.call({
            method: 'get',
            path: `/stories/${slug}`,
            contentType: 'json',
            params
        }).then((d) => {
            return new Page({
                componentRefs: d.data.story.content.body,
                apiRef: this
            }).retrieveComponents(0);
        });
    }

    getApiComponent(props: any) {
        const {
            slug,
            params
        } = props;

        return this.call({
            method: 'get',
            path: `/stories/${slug}`,
            contentType: 'json',
            params
        }).then((d) => {
            return {
                items: d.data.stories.map((story: object) => new ContentItem(story))
            };
        });
    }

    retrieveComponents(props: any) {
        const {
            componentRefs
        } = props;

        return this.call({
            method: 'get',
            path: `/stories`, // Find a way to not hardcode this.
            contentType: 'json',
            params: {
                'by_uuids': componentRefs.join(','),
                'resolve_relations': 'ContentCollection.content'
            }
        });
    }

    getItem (props: any) {
        const {
            slug
        } = props;

        return this.call({
            method: 'get',
            path: '/stories/games/' + slug
        }).then(d => {
            return new ContentItem(d.data.story);
        });
    }

    getRelated (props: ContentItem) {
        const slug = '/stories';

        return this.call({
            method: 'get',
            path: slug,
            contentType: 'json',
            params: {
                'with_tag': props.tags.join(',')
            }
        }).then(r => {
            return new Component({
                content: {
                    content: r.data.stories,
                    title: 'Related', // TODO use translate file,
                    type: 'carousel',
                    tile: 'ankeiler',
                }
            });
        });
    }

    getSuggestions () {
        const slug = '/stories';

        return this.call({
            method: 'get',
            path: slug,
            contentType: 'json',
            params: {
                'by_slugs': 'components/suggestions',
                'resolve_relations': 'ContentCollection.content'
            }
        }).then((r: any) => {
            return new Component(r.data.stories[0]);
        });
    }

    getSearchResults (query: string) {
        const slug = '/stories';

        return this.call({
            method: 'get',
            path: slug,
            contentType: 'json',
            params: {
                search_term: query,
                starts_with: 'games/'
            }
        }).then((r: any) => {
            return new Component({
                content: {
                    content: r.data.stories,
                    title: 'Search results',
                    type: 'carousel',
                    tile: 'ankeiler',
                }
            });
        });
    }

    getTags () {
        return this.call({
            method: 'get',
            path: '/tags',
            contentType: 'json'
        })
            .then((r: any) => {

                return r.data.tags;
            });
    }

    getSeries (series: string) {
        return this.call({
            method: 'get',
            path: `/stories/components/${series}`,
            contentType: 'json',
            params: {
                'resolve_relations': 'ContentCollection.content'
            }
        }).then((r: any) => new Component(r.data.story));
    }

    getFromDeveloper (developer: string) {
        return this.call({
            method: 'get',
            path: '/stories',
            contentType: 'json',
            params: {
                'resolve_relations': 'ContentCollection.content',
                'filter_query[developer][in]': developer
            }
        }).then((r: any) => new Component({
            content: {
                content: r.data.stories,
                title: Localise.get('moreFrom', { developer: developer }),
                type: 'carousel',
                tile: 'ankeiler',
            }})
        );
    }
}
