import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-361fd9e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid-component" }
const _hoisted_2 = { class: "grid grid-cols-1 xsm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_Asset = _resolveComponent("Asset")!
  const _component_Ankeiler = _resolveComponent("Ankeiler")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Title, {
      title: _ctx.componentData.title
    }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.componentData?.items, (item, key) => {
        return (_openBlock(), _createBlock(_component_router_link, {
          class: "item",
          key: key,
          to: '/game/' + item.slug
        }, {
          default: _withCtx(() => [
            (_ctx.isAssetType)
              ? (_openBlock(), _createBlock(_component_Asset, {
                  key: 0,
                  data: item
                }, null, 8, ["data"]))
              : (_ctx.isAnkeilerType)
                ? (_openBlock(), _createBlock(_component_Ankeiler, {
                    key: 1,
                    data: item
                  }, null, 8, ["data"]))
                : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1032, ["to"]))
      }), 128))
    ])
  ]))
}