
import {defineComponent} from "vue";

// @ts-ignore
import {Swiper, SwiperSlide} from "swiper/vue/swiper-vue";

import Component from "@/api/models/component";
import Ankeiler from "@/components/browse/tiles/Ankeiler.vue";
import Asset from "@/components/browse/tiles/Asset.vue";
import Title from "@/components/browse/Title.vue";
import ContentItem from "@/api/models/contentitem";
import Arrow from "@/components/browse/Arrow.vue";

export default defineComponent({
	name: 'Carousel',
	components: {
		Arrow,
		Ankeiler,
		Asset,
		Swiper,
		SwiperSlide,
		Title
	},
	props: {
		componentData: {
			type: Component,
			default: {}
		},
		itemCount: {
			default: 5
		},
		wrap: {
			default: true
		}
	},
	data: function () {
		return {
			slidesPerView: 4.5,
			isAssetType: this.componentData.tileType === 'asset',
			isAnkeilerType: this.componentData.tileType === 'ankeiler',
			showPrev: false,
			showNext: true,
			swiper: null
		}
	},
	methods: {
		activeIndexChange: function () {
			// @ts-ignore
			const current = this.swiper?.activeIndex;

			if (current !== 0) {
				this.showNext = true;
			}

			if (current !== 0) {
				this.showPrev = true;
			}
		},
		onSwiper: function (swiper:any) {
			this.swiper = swiper;
		},
		prev: function () {

			// @ts-ignore
			this.swiper.slidePrev();
		},
		next: function () {

			// @ts-ignore
			this.swiper.slideNext();
		},
		reachEnd: function () {
			this.showNext = false;
		},
		reachBegin: function () {
			this.showPrev = false;
		},
		arrowEnter: function () {
			// @ts-ignore
			this.$global.video.hide();
		},
		navigate: function (item: ContentItem) {
			this.$router.push(`/game/${item.slug}`);
		},
		onResize: function () {
			const width = window.innerWidth;
			const points = {
				xsm: 580,
				sm: 640,
				md: 768,
				lg: 1024,
				xl: 1280,
				'2xl': 1536
			}

			if (width < points.xsm) {
				this.slidesPerView = 1.2;
			} else if (width < points.sm) {
				this.slidesPerView = 1.7;
			} else if (width < points.md) {
				this.slidesPerView = 2.2;
			} else if (width < points.lg) {
				this.slidesPerView = 2.7;
			} else if (width < points.xl) {
				this.slidesPerView = 3.2;
			} else if (width < points['2xl']) {
				this.slidesPerView = 3.7;
			} else {
				this.slidesPerView = 4.2;
			}
		},
	},
	beforeMount() {
		this.onResize();
	},
	mounted() {
		window.addEventListener('resize', this.onResize);
	},
	beforeUnmount() {
		window.removeEventListener('resize', this.onResize);
	}
})
