
import {defineComponent} from "vue";
import SearchInput from "@/components/search/Input.vue";
import Component from "@/api/models/component";
import Grid from "@/components/browse/Grid.vue";
import Message from "@/components/search/Message.vue";

export default defineComponent({

  name: 'Search',
  components: {
    Message,
    SearchInput,
    Grid
  },
  data: function () {
    return {
      component: {} as Component,
      query: '',
      showNoResults: false,
      showHint: false,
      suggestions: {} as Component
    }
  },
  activated() {
    this.setMeta();
  },
  mounted: function () {
      this.getSuggestions();
  },
  methods: {
    setMeta: function () {
      const meta = {
        title: 'Search | EscapeGaming.io - Your home for escape room & puzzle games',
        description: 'Find your most loved escape the room and puzzle games, or discover new games to learn and love!'
      };

      this.$global.updateMeta(meta);
    },
    searchCallback: function (e: any) {
      const min = 3;

      this.query = e.target?.value;

      if (this.query?.length >= min) {
        this.$api.getSearchResults(this.query)
        .then((r: Component) => {

          this.showHint = false;
          this.showNoResults = !r.items.length;
          this.component = r;
        })
      } else if (this.query?.length > 0) {
        this.showHint = true;
        this.showNoResults = false;
        this.component = {} as Component;
      } else {
        this.showNoResults = false;
        this.showHint = false;
        this.component = this.suggestions;
      }
    },
    getSuggestions: function () {
      this.$api.getSuggestions()
          .then((r: Component) => {
            r.tileType = 'ankeiler';
            this.component = r;
            this.suggestions = r;
          });
    }
  }
})
