import EN from "../assets/lang/en.json";

export default {
    language: 'en',

    store: EN,

    get (value: string, replace?: object): string {
        // @ts-ignore
        let val = this.store[value] || '';

        // @ts-ignore
        Object.keys(replace || {}).forEach((k:string) => val = val.replace(`{{${k}}}`, replace[k]));

        return val;
    }
}