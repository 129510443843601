<template>
  <div class="loader flex items-center justify-items-center">
    <div class="text font-goldman">EscapeGaming.io</div>
  </div>
</template>

<style scoped lang="scss">
.loader {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  background-color:$contentBackgroundColor;
  color: $fontColor;
  width: 100%;
  height: 100%;

  @keyframes size {
    50% {

      transform: scale(0.75)
    }
  }

  .text {
    margin: 0 auto;
    font-size: 1.5em;

    @screen xsm {
      font-size: 2em;
    }

    @screen sm {
      font-size: 3em;
    }

    @screen md {
      font-size: 4em;
    }

    @screen lg {
      font-size: 5em;
    }

    animation: size 3s infinite;
  }
}
</style>