
import {defineComponent} from "vue";

// @ts-ignore
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"

export default defineComponent({
  name: 'Filter',
  components: {
    vSelect
  },
  props: {
    options: {
      type: Array
    },
    label: {
      type: String
    }
  },
  methods: {
    emitEvent () {
      this.$emit('filterSelect', this.selected)
    }
  },
  beforeMount: function () {
    this.selected = this.options ? this.options[0] : {};
  },
  data: function () {
    return {
      selected: {} as any
    }
  }
})
