export default class Interface {


    call (props: any) { // eslint-disable-line no-unused-vars

        // Implement call functionality.
    }

    getMenu () {

        // Retrieves the app menu.
    }

    getPage (props: any) {

        // Implement page retrieval.
    }

    retrieveComponents (props: any) {

        // Implement component retrieval.
    }
}