import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2213a298"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "carousel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_Arrow = _resolveComponent("Arrow")!
  const _component_Asset = _resolveComponent("Asset")!
  const _component_Ankeiler = _resolveComponent("Ankeiler")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_SwiperSlide = _resolveComponent("SwiperSlide")!
  const _component_Swiper = _resolveComponent("Swiper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Title, {
      title: _ctx.componentData.title
    }, null, 8, ["title"]),
    _createVNode(_component_Swiper, {
      ref: "swiper",
      "slides-per-view": _ctx.slidesPerView,
      "space-between": 20,
      onReachEnd: _ctx.reachEnd,
      onReachBeginning: _ctx.reachBegin,
      onActiveIndexChange: _ctx.activeIndexChange,
      onSwiper: _ctx.onSwiper
    }, {
      "container-start": _withCtx(() => [
        _withDirectives(_createVNode(_component_Arrow, {
          direction: "left",
          class: "left",
          ref: "prevArrow",
          onMouseenter: _ctx.arrowEnter,
          onClick: _ctx.prev
        }, null, 8, ["onMouseenter", "onClick"]), [
          [_vShow, _ctx.showPrev]
        ])
      ]),
      "container-end": _withCtx(() => [
        _withDirectives(_createVNode(_component_Arrow, {
          direction: "right",
          class: "right right-arrow",
          ref: "nextArrow",
          onMouseenter: _ctx.arrowEnter,
          onClick: _ctx.next
        }, null, 8, ["onMouseenter", "onClick"]), [
          [_vShow, _ctx.showNext]
        ])
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.componentData.items, (item, key) => {
          return (_openBlock(), _createBlock(_component_SwiperSlide, { key: key }, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, {
                to: '/game/' + item.slug
              }, {
                default: _withCtx(() => [
                  (_ctx.isAssetType)
                    ? (_openBlock(), _createBlock(_component_Asset, {
                        key: 0,
                        data: item
                      }, null, 8, ["data"]))
                    : (_ctx.isAnkeilerType)
                      ? (_openBlock(), _createBlock(_component_Ankeiler, {
                          key: 1,
                          data: item
                        }, null, 8, ["data"]))
                      : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["to"])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["slides-per-view", "onReachEnd", "onReachBeginning", "onActiveIndexChange", "onSwiper"])
  ]))
}