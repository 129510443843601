import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_List = _resolveComponent("List")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["browse", { 'top-margin': _ctx.topMargin} ])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.components, (component, key) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "component",
        key: key
      }, [
        _createVNode(_component_List, {
          if: "isList(component)",
          "component-data": component,
          "filter-options": _ctx.tags
        }, null, 8, ["component-data", "filter-options"])
      ]))
    }), 128))
  ], 2))
}