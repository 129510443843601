export default class MenuItem {
    label: string;
    slug: string;
    path: string;
    type: string;
    params?: object;

    constructor (data: any) {
        this.label = data.label;
        this.slug = data.slug;
        this.path = data.path;
        this.type = data.type;

        if (data.params) {
            this.params = JSON.parse(data.params);
        }
    }
}
