

import {defineComponent} from "vue";

export default defineComponent({

  props: {
    callback: {
      default: console.log
    }
  }
})
