export default class ContentItem {
    title: string
    description: string
    long_description: string
    images: {
        header?: string
        tile?: string
    }
    slug: string
    links: {
        steam: string
    }
    website: string
    tags: Array<string>
    rating?: string
    releaseDate?: string
    developer?: string
    videos?: {
        trailer?: string,
        walkthrough?: string,
        embedded?: string
    }
    series?: string
    released?: boolean

    constructor(props: any) {
        const content = props.content || {};
        this.title = content.title;
        this.description = this.escapeHTML(content.short_description || content.long_description);
        this.long_description = content.long_description;
        this.images = content.images && content.images[0];
        this.slug = content.slug;
        this.links = content.links && content.links[0];
        this.tags = props.tag_list;
        if (content.release_date) {
            this.releaseDate = new Date(content.release_date).toLocaleString('nl-NL').split(' ')[0];
        }
        this.rating = content.content_rating;
        this.developer = content.developer;
        this.website = content.website;
        this.videos = {
            trailer: content.trailer,
            walkthrough: content.walkthrough,
            embedded: content.walkthrough && 'https://www.youtube.com/embed/' + this.getYoutubeUrl(content.walkthrough)
        };
        this.series = content.series;
        this.released = !content.Released; // Welp.
    }

    getYoutubeUrl (url: string) {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);

        return (match && match[2].length === 11)
            ? match[2]
            : null;
    }

    escapeHTML (e: string) {
        const doc = new DOMParser().parseFromString(e, "text/html");
        return doc.documentElement.textContent || '';
    }
}