

import Grid from "@/components/browse/Grid.vue";
import {defineComponent} from "vue";
import Component from "@/api/models/component";
import List from "@/components/browse/List.vue";
import Carousel from "@/components/browse/Carousel.vue";
import Header from "@/components/browse/Header.vue";

export default defineComponent({
  name: 'Browse',
  components: {
    Header,
    List,
    Grid,
    Carousel
  },
  activated() {
    this.setMeta();
  },
  created () {
    const slug = this.$route.meta.slug;
    this.mounted++;
    this.$api.getPage({
      slug
    }).then(r => {
      this.pageData = r;
      this.components = r.components;
      this.topMargin = !!(this.components[0] && this.components[0].title);
    });
  },
  data: () => {
    return {
      components: {} as Array<Component>,
      pageData: {},
      mounted: 0,
      topMargin: false
    }
  },
  methods: {
    setMeta () {
      const meta = {
        title: `${this.$route.meta.label} | EscapeGaming.io - Your home for escape room & puzzle games`,
        description: 'Find your favourite escape the room and puzzle games, or discover new games to learn and love!'
      }

      this.$global.updateMeta(meta);
    },
    isGrid: (component: Component) => {
      return component.type === 'grid';
    },
    isHeader: (component: Component) => {
      return component.type === 'header';
    },
    isList: (component: Component) => {
      return component.type === 'list';
    },
    isCarousel: (component: Component) => {
      return component.type === 'carousel';
    }
  }
});
